import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Slider from 'react-slick';
import styled from 'styled-components';
import { OnePageLayout } from 'layouts';
import Config from 'Data';
import { useCountUp } from 'react-countup';
import Vimeo from '@u-wave/react-vimeo';
import {
  TwitterShareButton,
  TwitterFollowButton,
  TwitterTweetEmbed,
} from 'react-twitter-embed';

import {
  FeaturedSection,
  FormAttend,
  BackgroundImage,
  BackgroundVideo,
  EventCalendarCard,
  Hero,
  HeroBig,
  HeroVideo,
  Logo,
  PostList,
  SectionBackground,
  SectionCallout,
  SectionCard,
  FormShareEvent,
  EventsGrid,
  FooterCTA,
  SEO,
  SocialShare,
  SectionChart,
  SectionTweets,
} from 'Components';

import {
  theme,
  media,
  mixins,
  ActionButton,
  ActionButtonAlt,
  ActionHref,
  ActionHrefAlt,
  AddToCalendarStyles,
  Button,
  Carousel,
  CenterSection,
  Container,
  EventCard,
  EventCardSubtitle,
  EventCardTitle,
  ActionSection,
  FirstSection,
  FlatSection,
  FooterSection,
  Wrapper,
  FullWrapper,
  Gap,
  GlobalStyle,
  Header,
  Heading,
  InlineLink,
  Main,
  Nav,
  Section,
  Shybar,
  TestimonialAuthor,
  TestimonialAuthorDetails,
  TestimonialAuthorLogo,
  TestimonialAuthorName,
  TestimonialAuthorTitle,
  TestimonialCard,
  TestimonialCopy,
  TestimonialText,
  Text,
  FlatText,
  FlatImage,
  Title,
  Subtitle,
  BackgroundSection,
  SliderContent,
  StyledLink,
  Partners,
  SectionHeading,
  SectionHeadingButton,
  FormGroup,
} from 'Styles';

const MODAL_ATTEND = 'modal_attend';
const MODAL_SPONSOR = 'modal_sponsor';
const MODAL_SHARE_EVENT = 'modal_share_event';

const SliderSettings = {
  // className: 'xxx',
  // dotsClass: 'aaa',
  autoplay: true,
  useTransform: true,

  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  pauseOnHover: true,
  initialSlide: 1,
  responsive: [
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        infinite: true,
        dots: true,
      },
    },
  ],
};

const TestimonialVideo = styled.div`
  iframe {
    width: 100%;
    height: 180px;
  }
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
  }
  @media (min-width: ${theme.breakpoints.l}) {
  }
`;

const ActionsGrid = styled.div`
  // margin: 2rem auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
  @media (min-width: ${theme.breakpoints.s}) {
  }
  @media (min-width: ${theme.breakpoints.m}) {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  }
  @media (min-width: ${theme.breakpoints.l}) {
    grid-template-columns: repeat(auto-fill, minmax(31%, 1fr));
  }
  grid-gap: 32px;
`;

const ActionsBlock = styled.div`
  // color: white;
  background: #fff;
  padding: 2rem;
  border-radius: ${theme.borderRadius.ui};
  box-shadow: ${theme.shadow.feature.small.default};
`;

const ActionsTitle = styled.h3`
  color: inherit;
  border-bottom: 1px solid ${theme.colors.primary.base};
  padding-bottom: 1rem;
  p {
    margin: 0;
  }
`;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentModal: null,
      shareEvent: null,
    };
  }

  componentWillMount() {
    this.setState({
      currentModal: null,
      shareEvent: null,
    });
  }

  toggleModal = (key, event) => e => {
    e.preventDefault();
    if (this.state.currentModal) {
      this.handleModalCloseRequest();
      return;
    }

    this.setState({
      ...this.state,
      currentModal: key,
      shareEvent: event,
    });
  };

  handleOnScroll = e => {
    let element = e.target;
    if (element.scrollHeight - element.scrollTop === element.clientHeight) {
      console.log('handleOnScroll');
    }
  };

  handleModalCloseRequest = () => {
    // opportunity to validate something and keep the modal open even if it
    // requested to be closed
    this.setState({
      ...this.state,
      currentModal: null,
    });
  };

  handleOnAfterOpenModal = () => {};

  componentDidMount() {
    window.addEventListener('scroll', this.handleOnScroll, true);
  }

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.handleOnScroll);
  };

  render() {
    const { data } = this.props;
    const hero = data.index.page.home;
    const { banner } = data.banners;
    const featured = data.content.featured;
    const cta = data.cta;
    const sections = data.content.sections;
    const { testimonials } = data.testimonials;
    const fullSection = data.content.fullSection;
    const { currentModal } = this.state;

    return (
      <OnePageLayout sticky>
        <Helmet title={`${Config.brandBusiness}`} />
        <SEO
          pathname="/"
          title={`${Config.brandBusiness}`}
          description={hero.subtitle || ' '}
          banner={hero.heroImage.childImageSharp.fluid.src}
        />
        {/* <SocialShare
          pathname="/"
          title={`${Config.brandBusiness}`}
          description={hero.subtitle || ' '}
          banner={hero.heroImage.childImageSharp.fluid.src}
        /> */}
        <Hero
          height={100}
          featured={true}
          title={`${hero.title}`}
          subtitle={`${hero.subtitle}`}
          // cta_text1={'aaaaaaa'}
          // cta_action1={this.toggleModal(MODAL_ATTEND)}
          // cta_text2={'bbbbbb'}
          // cta_action2={this.toggleModal(MODAL_SPONSOR)}
          image={hero.heroImage}
          animated={false}
          // vimeoId={hero.video}
          // youtubeId={hero.video}
        />
        <FormAttend
          title="Title"
          subtitle="Subtitle"
          banner={banner.attend}
          isOpen={currentModal == MODAL_ATTEND}
          onAfterOpen={this.handleOnAfterOpenModal}
          onRequestClose={this.handleModalCloseRequest}
          closeModal={this.toggleModal(MODAL_ATTEND)}
        />
      </OnePageLayout>
    );
  }
}

export default Index;

export const query = graphql`
  query IndexQuery {
    index: pagesJson {
      page {
        home {
          title
          subtitle
          video
          heroImage {
            childImageSharp {
              fluid(maxWidth: 2880, quality: 90) {
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
              }
            }
          }
        }
      }
    }
    banners: bannersJson {
      banner {
        attend {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        sponsor {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
        sharing {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
    content: homeJson {
      featured {
        title
        copy
        cta_text
        cta_link
        cta_text_1
        cta_link_1
        image {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
      sections {
        title
        copy
        cta_text
        cta_link
        cta_text_1
        cta_link_1
        video
        image {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
      fullSection {
        title
        copy
        video
        cta_text
        cta_link
        cta_text_1
        cta_link_1
        image {
          childImageSharp {
            id
            fluid(maxWidth: 2880, quality: 90) {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
    testimonials: testimonialsJson {
      testimonials {
        copy
        author
        website
        title
        category
        video
        image {
          childImageSharp {
            fluid(maxHeight: 60) {
              ...GatsbyImageSharpFluid
            }
            sizes {
              base64
              aspectRatio
              src
              srcSet
              srcWebp
              srcSetWebp
              sizes
              originalImg
              originalName
            }
          }
        }
      }
    }
  }
`;
